<template>
  <b-button-toolbar justify>
    <b-button-group>
      <b-button @click="reFetchData" variant="outline-primary" size="md">
        <feather-icon icon="RefreshCcwIcon" size="18"/>
        <span class="align-middle"></span>
      </b-button>
      <b-button :to="newRoute" @click="newClick" variant="primary" size="md" v-if="newRoute || newClick">
        <feather-icon icon="PlusCircleIcon" size="18"/>
        <span class="align-middle"></span>
      </b-button>
    </b-button-group>
  </b-button-toolbar>
</template>

<script>
import {BButton, BButtonGroup, BButtonToolbar} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BButtonGroup,
    BButtonToolbar,
  },
  props: ['newRoute', 'reFetchData', 'newClick'],
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
